.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  background-color: #2c3e50;
  color: white;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
  transition: width 0.3s ease;
  width: 250px;
}

.sidebar.collapsed {
  width: 60px;
}

.sidebarMenu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebarMenuItem {
  padding: 15px 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.sidebarMenuLink {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: 100%;
}

.sidebarMenuLink:hover {
  background-color: #34495e;
}

.menuIcon {
  font-size: 1.5em;
  margin-right: 15px;
}

.sidebar.collapsed .menuIcon {
  margin-right: 0;
  text-align: center;
  width: 100%;
}

.menuText {
  white-space: nowrap;
}

.sidebar.collapsed .menuText {
  display: none;
}

.dropdownMenu {
  list-style: none;
  padding: 0;
  margin: 0 0 0 20px;
}

.dropdownMenu li {
  padding: 10px 0;
}

.dropdownMenu li a {
  color: white;
  text-decoration: none;
}

.dropdownMenu li a:hover {
  background-color: #3c5a74;
}

.toggleButton {
  position: absolute;
  top: 10px;
  right: -20px;
  width: 40px;
  height: 40px;
  background-color: #34495e;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.toggleButton:hover {
  background-color: #3c5a74;
}
