.mainPageBodyScroller.mainSettings {
  width: calc(100% - 340px);
  margin: auto;
  background-color: #FFFFFF;

  @media screen and (max-width: 768px){
    width: 95%;
    padding-top: 0;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 576px){
    width: 90%;
  }

  .horizontal__tabs{
    background: #F3F4F8;
    .MuiTabs-root {
        .MuiTabs-indicator{
            display: none;
        }
    }
    .MuiTabs-flexContainer {
        overflow-x: auto;
        padding-bottom: 10px;

        @media screen and (max-width: 576px){
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px 0;
        }

        .MuiButtonBase-root{
            border: 1px solid #AEB6CF;
            border-radius: 5px;
            margin-right: 10px;

            &:last-child{
                margin-right: 0;
            }

            &.Mui-selected{
                border: 1px solid #1976d2;
            }

            @media screen and (max-width: 768px){
                font-size: 14px;
                min-height: 40px;
                padding: 5px 10px;
            }

            @media screen and (max-width: 576px){
                max-width: 100%;
                margin-right: 0;
                background-color: #FFF;
            }
        }
    }
  }

  .tab__panel{
    .MuiBox-root{
        @media screen and (max-width: 576px){
            padding: 12px;
        }
    }
  }

  .form-submit {
    background: #18a0fb;
    border-radius: 4px;
    padding: 8px 16px;
    width: -moz-fit-content;
    width: fit-content;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #ffffff;
    margin-top: 30px;
    position: absolute;
    left: 0;
    bottom: 0;

    @media screen and (max-width: 1280px){
        position: unset;
    }

    @media screen and (max-width: 768px){
        width: 100%;
        margin-top: 20px;
    }
  }

  .profile {
    min-height: 480px;
    position: relative;

    &__box {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      cursor: pointer;
      margin-bottom: 50px;
      @media screen and (max-width: 768px){
        margin-bottom: 20px;
      }
    }

    &__photo {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      margin-right: 20px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
      }

      .MuiSvgIcon-root {
        position: absolute;
        right: -6px;
        bottom: 0;
        width: 14px;
        height: 14px;
        path {
          fill: #18a0fb;
        }
      }
    }

    &__text {
      font-family: "Poppins", sans-serif;
      font-weight: 500;
      font-size: 16px;
      line-height: 1;
      letter-spacing: 0.03em;
      color: #18a0fb;
    }

    &__form {
      display: flex;
      flex-direction: column;

      .form-group {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 30px;
        @media screen and (max-width: 1280px){
            flex-direction: column;
        }
        @media screen and (max-width: 768px){
            margin-bottom: 20px;
        }

        .form-label {
          padding: 10px 0 0 0;
          min-width: 145px;
          margin: 0 90px 0 0;
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #18a0fb;
          display: flex;

          @media screen and (max-width: 1280px){
            margin: 0 0 10px 0;
          }
        }

        .star {
          color: red;
          padding-left: 2px;
        }

        .form-input {
          border: 1px solid rgba(174, 182, 207);
          filter: drop-shadow(0px 1px 0px rgba(0, 0, 0, 0.25));
          transition: all 0.3s;
          flex: 1;
          border-radius: 10px;
          height: 42px;
          padding: 8px 10px;
          max-width: 320px;
          @media screen and (max-width: 1280px){
            width: 100%;
          }
          @media screen and (max-width: 768px){
            max-width: 100%;
          }

          &:hover {
            border: 1px solid #18a0fb;
            transition: all 0.3s;
          }
          &:focus {
            outline: 0;
          }
        }

        .input-group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: auto;
          .form-input {
            width: 100%;
          }
        }

        .input-description {
          font-family: "Poppins", sans-serif;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #8a94a6;
          margin: 16px 0 0 0;

          @media screen and (max-width: 768px){
            br{
                display: none;
            }
          }
        }

        .MuiFormControl-root {
          margin-right: 100px;
          @media screen and (max-width: 576px){
            margin-right: 0;
            margin-bottom: 20px;
          }

          &:last-child {
            margin-right: 0;
          }

          .MuiInputBase-root {
            .MuiSelect-select {
              font-family: "Poppins", sans-serif;
              font-weight: 500;
              font-size: 14px;
              line-height: 21px;
              letter-spacing: 0.03em;
              color: #18a0fb;
            }
            .MuiOutlinedInput-notchedOutline {
              display: none;
            }
          }
        }

        .plan {
          &__list {
            list-style-type: none;
            padding: 0;
            margin: 0;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
            width: 100%;

            @media screen and (max-width: 1280px){
                grid-template-columns: 1fr;
            }

            &--item {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              h2 {
                font-family: "Poppins", sans-serif;
                font-weight: 500;
                font-size: 14px;
                line-height: 21px;
                letter-spacing: 0.03em;
                color: #18a0fb;
                margin: 0 40px 0 0;
                @media screen and (max-width: 1280px){
                    min-width: 150px;
                }
              }
              p {
                font-family: "Poppins", sans-serif;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                letter-spacing: -0.015em;
                color: #8a94a6;
                margin: 0;
              }
            }
          }
        }

        .form-select-group{
            display: flex;
            @media screen and (max-width: 1280px){
                margin-top: 40px;
            }
            @media screen and (max-width: 576px){
                margin-top: 20px;
                flex-direction: column;
            }
            .MuiFormControl-root{
                .MuiInputBase-root {
                    .MuiSelect-select{
                        padding-left: 0;
                    }
                }
            }
        }
      }

      .form__flex{
        .form-group{
            margin-bottom: 20px;

            .btn-delete{
                margin: 0 0 0 10px;
                border-radius: 10px;
                background: #18a0fb;
                color: #FFFFFF;
                font-size: 14px;
                padding: 8px 14px;
                width: fit-content;

                @media screen and (max-width: 1280px){
                    margin: 10px 0 0 0;
                }
            }
        }

        & + .form-submit{
            position: unset;
        }
      }
    }

    .profile-payment-api{
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px){
            flex-direction: column;

            .form-select-group{
                margin-top: 20px;
            }
        }

        p{
            flex: 0 0 50%;
        }
    }
  }

  .upload__form {
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
    border-radius: 5px;
    padding: 50px 100px;
    max-width: 750px;
    position: relative;

    @media screen and (max-width: 1024px){
        padding: 50px 20px;
    }
    @media screen and (max-width: 768px){
        padding: 20px;
    }

    .cancel{
      position: absolute;
      right: 30px;
      top: 30px;
      cursor: pointer;
    }

    &--title{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #8A94A6;
        margin: 0 0 25px 0;
        @media screen and (max-width: 576px){
            text-align: center;
        }
    }
    .fileUploadInner {
      display: flex;
      align-items: center;

      @media screen and (max-width: 576px){
        flex-direction: column;
      }

      .fileUpload {
        margin: 0 30px 0 0;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background: rgba(24, 160, 251, 0.2);
        border: 1px solid #ffffff;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex: 0 0 130px;

        @media screen and (max-width: 576px){
            margin: 0 0 20px 0;
        }

        input {
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 20;
          opacity: 0;
          cursor: pointer;
        }

        img {
          width: 100%;
          height: 100%;
          z-index: 10;

          &.staticImg{
            object-fit: scale-down;
          }
          &.uploadedImg{
            object-fit: cover;
          }
        }

        .dotted-line{
            width: 100px;
            height: 65px;
            position: absolute;
            border: 1px dashed #18A0FB;
            border-radius: 4px;
        }
      }

      .fileText{
        display: flex;
        flex-direction: column;
      }

      h4 {
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #18A0FB;
      }

      span{
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #8A94A6;
      }
    }

    .form-submit{
        position: relative;
        width: 130px;
        text-align: center;

        @media screen and (max-width: 576px){
            width: 100%;
        }
    }

    .avatar{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
    }

  }

}

.h-max-200 {
  max-height: 200px !important;
}

.form__grid{
    display: flex;
    justify-content: flex-start;
    gap: 100px;
}

.payment-table{
    max-height: 140px;
    table{
        thead{
            tr{
                th, td{
                    font-family: "Poppins", sans-serif;
                    background-color: #76A0BC;
                    padding: 5px 10px;
                    position: sticky;
                    top: 0;
                    z-index: 1;
                }
            }
        }
        tbody{
            tr{
                border: 1px solid #AEB6CF;
                th, td{
                    padding: 5px 10px;
                    font-family: "Poppins", sans-serif;
                }
            }
        }
        
    }

    .form-group .MuiFormControl-root .MuiInputBase-root .MuiSelect-select {
        color: #000000DE !important;
    }
}
