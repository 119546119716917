.wrapper {
    position: relative;
    width: 1440px;
    height: 800px;
}

.leftWrapper {
    position: absolute;
    width: 617px;
    height: 800px;
    top: 0px;
    background: #FFFFFF;
}

.rightWrapper {
    background: #E5E5E5;
    position: absolute;
    width: 823px;
    height: 800px;
    left: 617px;
    top: 0px;
}

._3bARu {
    top: 50% !important;
}

.loaderWrap{
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0,0,0,0.7);
    display: flex;
    justify-content: center;
    align-items: center;
}