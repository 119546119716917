.signup-logo {
    position: absolute;
    width: 121.94px;
    height: 88px;
    left: 87px;
    top: 140px;
}

.signup-heading {
    position: absolute;
    left: 10%;
    right: 28.61%;
    top: 30.62%;
    bottom: 59.62%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    color: #181818;
}

.signup-sub-heading {
    position: absolute;
    left: 10%;
    right: 39.24%;
    top: 40.38%;
    bottom: 54.88%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #181818;
}

.dontAccount {
    position: absolute;
    width: 127px;
    height: 15px;
    left: 576px;
    top: 55px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #595959;
}

.buttonSignIn {
    box-sizing: border-box;
    position: absolute;
    width: 59px;
    height: 24px;
    left: 714px;
    top: 50px;
    border: 1px solid #18A0FB;
    cursor: pointer;
}

.signInText {
    position: absolute;
    width: 43px;
    height: 15px;
    left: 8px;
    top: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #18A0FB;
}

.signUpContent {
    position: absolute;
    left: 103px;
    right: 413px;
    top: 476px;
    bottom: 276px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: -0.015em;
    color: #8A94A6;
}

.signUpLineBefore {
    position: absolute;
    width: 104px;
    height: 0px;
    left: 87px;
    top: 597.28px;
    border: 1px solid #AEB6CF;
}

.signUpLineAfter {
    position: absolute;
    width: 104px;
    height: 0px;
    left: 297px;
    top: 597.28px;
    border: 1px solid #AEB6CF;
}

.signWithText {
    position: absolute;
    width: 110.51px;
    height: 18.59px;
    left: 191px;
    top: 588px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #181818;
}

.signUpTextContent {
    position: absolute;
    width: 314px;
    height: 32px;
    left: 87px;
    top: 671px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.03em;
    color: #595959;
}

.profile-logo {
    position: absolute;
    width: 121.94px;
    height: 88px;
    left: 73px;
    top: 91px;
}

.profile-heading {
    position: absolute;
    left: 73px;
    right: 35.21%;
    top: 26.5%;
    bottom: 68.75%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 25px;
    line-height: 38px;
    display: flex;
    align-items: center;
    color: #181818;
}

.confirm-logo {
    position: absolute;
    left: 395px;
    top: 185.84px;
}

.confirm-line {
    position: absolute;
    left: 246px;
    top: 213px;
}

.confiration-heading {
    position: absolute;
    left: 189px;
    top: 300.96px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    align-items: center;
    color: #181818;
}

.confirmationContent {
    position: absolute;
    left: 52px;
    right: 3.47%;
    top: 374.96px;
    bottom: 44.88%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #181818;
}

.confiration-sub-heading {
    position: absolute;
    height: 33px;
    left: 243px;
    top: 461.96px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #8A94A6;
}

.confiration-link {
    position: absolute;
    height: 30px;
    left: 303px;
    top: 516.96px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 30px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #18A0FB;
    cursor: pointer;
}

.industry {
    position: absolute;
    width: 430.72px;
    height: 430.72px;
    left: 86.37px;
    top: 138px;
}

.industryText {
    position: absolute;
    left: 48px;
    top: 584.48px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 39px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #8A94A6;
}

.backIcon {
    position: absolute;
    width: 52px;
    height: 52px;
    left: 646px;
    top: 698px;
    background: rgba(24, 160, 251, 0.5);
    border-radius: 4px;
    cursor: pointer;
}

.backIcon:hover {
    background: #18A0FB
}

.nextIcon {
    position: absolute;
    width: 52px;
    height: 52px;
    left: 721px;
    top: 698px;
    background: rgba(24, 160, 251, 0.5);
    border-radius: 4px;
    cursor: pointer;
}

.nextIcon:hover {
    background: #18A0FB
}

.welcomeText {
    position: absolute;
    left: 103px;
    top: 29.5%;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 39px;
    display: flex;
    align-items: center;
    color: #8A94A6;
    width: 100%;
}

.skipBtn {
    width: 33px;
    height: 24px;
    left: 741px;
    top: 46px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    color: #8A94A6;
    cursor: pointer;
}

.swal2-styled.swal2-confirm {
    background-color: #18A0FB;
}

@media screen and (max-width: 1368px){
    .confiration-heading, .signup-heading{
        font-size: 32px;
        line-height: 50px;
        margin-bottom: 8px;
    }
    .confirmationContent, .confiration-sub-heading, .confiration-link, .signup-sub-heading{
        font-size: 20px;
        line-height: 30px;
    }
    .welcomeText{
        font-size: 20px;
        line-height: 30px;
    }
}

@media screen and (max-width: 768px){
    .confiration-heading, .signup-heading{
        font-size: 24px;
        line-height: 40px;
    }
    .confirmationContent, .confiration-sub-heading, .confiration-link, .signup-sub-heading{
        font-size: 18px;
        line-height: 28px;
    }
}

@media screen and (max-width: 540px){
    .welcomeText{
        font-size: 18px;
        line-height: 28px;
    }
}