.teamRegister {
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  * {
    box-sizing: border-box;
  }
  .leftRegister {
    width: 60%;
    height: 100%;
    text-align: center;
    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .rightRegister {
    width: 40%;
    // height: 100%;
    overflow: auto;
    background-color: #f3f4f8;
    height: 100vh;
    padding: 50px 80px 20px 80px;
    position: relative;

    @media screen and (min-width: 767px) and (max-width: 1200px) {
      padding: 50px 50px 20px 50px;
    }
    @media screen and (min-width: 991px) and (max-width: 1200px) {
      width: 50%;
    }
    @media screen and (min-width: 767px) and (max-width: 990px) {
      width: 60%;
    }

    @media screen and (max-width: 1340px) {
      .formWrap {
        .row {
          .col-md-12 {
            flex: 0 0 100%;
            max-width: 100%;
          }
        }
      }
    }

    @media screen and (max-width: 768px) {
      height: 100%;
    }

    .registerHeader {
      .logoRegister {
        margin-bottom: 20px;
      }
      h1 {
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        color: #181818;
      }
      p {
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.03em;
        color: #8a94a6;
        @media screen and (max-width: 1368px) {
          font-size: 16px;
          line-height: 25px;
        }
        @media screen and (max-width: 540px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .row {
      label {
        width: 100%;
      }
    }
    .registerFieldLabel {
      font-size: 14px;
      line-height: 21px;
      letter-spacing: 0.03em;
      color: #181818;
      position: relative;
      text-align: left;
      margin-bottom: 10px;
    }
    .registerField {
      margin-bottom: 25px;
      input {
        width: 100%;
        height: 40px;
        &:hover,
        &:focus {
          border: 1px solid #18a0fb;
        }
      }
    }
    button {
      &.btn {
        &.btnDefault {
          background: #18a0fb;
          border-radius: 12px;
          color: #fff;
          width: 300px;
          max-width: 100%;
          margin-bottom: 15px;
          font-size: 15px;
          height: 40px;
        }
      }
    }
    &.finshiedWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      .registerFinishWrap {
        background-repeat: no-repeat;
        background-position: top, center;
        padding-top: 90px;
        text-align: center;
        padding-bottom: 40px;
        h1 {
          font-size: 40px;
          line-height: 60px;
          color: #181818;
          margin-bottom: 15px;
        }
        h4 {
          font-weight: 500;
          font-size: 22px;
          line-height: 33px;
          color: #181818;
          margin-bottom: 15px;
        }
        h5 {
          font-weight: 300;
          font-size: 22px;
          line-height: 33px;
          color: #8a94a6;
          margin-bottom: 15px;
        }
        a {
          font-weight: 300;
          font-size: 20px;
          line-height: 30px;
          text-decoration-line: underline;
          color: #18a0fb;
        }
      }
    }
    .signUplink {
      position: absolute;
      right: 80px;
      top: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      @media screen and (min-width: 767px) and (max-width: 1200px) {
        right: 50px;
      }

      @media screen and (max-width: 767px) {
        right: 15px;
        top: 15px;
      }
      p {
        font-family: "Poppins", sans-serif;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #595959;
        margin: 0 10px 0 0;
        font-weight: 500;
      }
      button {
        &.btn {
          &.btnDefault {
            border: 1px solid #18a0fb;
            font-family: "Poppins", sans-serif;
            font-size: 10px;
            line-height: 15px;
            text-align: center;
            letter-spacing: 0.03em;
            color: #18a0fb;
            background: transparent;
            width: 70px;
            height: 24px;
            border-radius: 0;
            margin: 0;
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 500;
          }
        }
      }
    }
    .invalid-feedback {
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      margin-left: 4px;
      white-space: normal;
    }
  }
}
.forgetPassword {
  .rightRegister {
    .registerHeader {
      h1 {
        margin-bottom: 50px;
      }
    }
    .registerField {
      margin-bottom: 40px;
      .invalid-feedback {
        bottom: 10px;
      }
    }
    button.btn.btnDefault {
      width: 100%;
    }

    .formWrap {
      label {
        width: 100%;
      }

      .btn {
        height: 40px;
      }

      @media screen and (max-width: 991px) {
        width: 100%;
      }
    }
  }
  &.tokenVerify {
    .rightRegister {
      .registerField {
        margin-bottom: 25px;
      }
    }
  }
}

@media (max-width: 767px) {
  .teamRegister {
    .leftRegister {
      display: none;
    }
    .rightRegister {
      width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .teamRegister {
    .rightRegister {
      width: 100%;
      padding: 25px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}

@media (max-width: 415px) {
  .teamRegister {
    .rightRegister {
      button {
        &.btn {
          &.btnDefault {
            width: 100%;
          }
        }
      }
      .signUplink {
        right: 15px;
        top: 15px;
      }
    }
  }
}
