.inputField {
    width: 100%;
    height: 40px;
    box-sizing: border-box;
    /* filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25)); */
    border: 1px solid rgba(174, 182, 207, 0.25);
    border-radius: 12px;
    padding:8px;
    transition: all 0.3s;
}

.inputField::placeholder{
    font-size: 0.875rem;
    font-weight: 400;
}

.inputField:hover{
    border: 1px solid #18A0FB;
    transition: all 0.3s;
}

.inputField:focus{
    outline:0;
    border: 1px solid #18A0FB;
    transition: all 0.3s;
}

.labelField {
    position: absolute;
    font-style: normal;
    line-height: 21px;
    text-align: center;
    color: #181818;
    font-weight: 500;
    font-size: 14px;
}

input[type='checkbox'] {
    accent-color: #18A0FB;
}

input::placeholder {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #AEB6CF;
}

textarea::placeholder {
    position: absolute;
    height: 23.75px;
    left: 5.16px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    text-align: initial;
    letter-spacing: 0.03em;
    color: #AEB6CF;
}

.checkBoxField {
    box-sizing: border-box;
    position: absolute;
}

.customBtn {
    position: absolute;
    background: #18A0FB;
    box-shadow: 0px 4px 10px rgba(24, 160, 251, 0.15);
    border-radius: 12px;
    cursor: pointer !important;
}

.signUpEmailLabel {
    width: 42.34px;
    height: 21.69px;
    left: 86px;
    top: 385px;
}

.signUpEmail {
    position: absolute;
    width: 313.97px;
    height: 41.31px;
    left: 86.97px;
    top: 412.89px;
}

.signupCheckbox {
    width: 9px;
    height: 9px;
    left: 86px;
    top: 478px;
    border: 0.5px solid #8A94A6;
    border-radius: 1px;
}

.signUpBtn {
    width: 313.97px;
    height: 40px;
    left: 87.03px;
    top: 524px;
}

.signUpBtnText {
    position: absolute;
    width: 194px;
    height: 22px;
    left: 60px;
    top: 11.28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #FFFFFF;
}

.profileFname {
    width: 313.97px;
    height: 41.31px;
    left: 76.03px;
    top: 310.88px;
    position: absolute;
}

.profileFnameLabel {
    width: 84px;
    height: 22px;
    left: 75px;
    top: 283px;
}

.profileLname {
    width: 313.97px;
    height: 41.31px;
    left: 435.03px;
    top: 310.88px;
    position: absolute;
}

.profileLnameLabel {
    width: 84px;
    height: 22px;
    left: 434px;
    top: 274px;
}

.profileEmail {
    width: 673px;
    height: 41px;
    left: 76px;
    top: 417px;
    position: absolute;
}

.profileEmailLabel {
    width: 44px;
    height: 22px;
    left: 74px;
    top: 389px;
}

.profilePassword {
    width: 313.97px;
    height: 41.31px;
    left: 76px;
    top: 518.89px;
    position: absolute;
}

.profilePasswordLabel {
    width: 75px;
    height: 22px;
    left: 76px;
    top: 491px;
}

.profileRePassword {
    width: 313.97px;
    height: 41.31px;
    left: 435px;
    top: 518.89px;
    position: absolute;
}

.profileRePasswordLabel {
    width: 100px;
    height: 22px;
    left: 435px;
    top: 491px;
}

.profileBtn {
    width: 313.97px;
    height: 40px;
    left: 76px;
    top: 599px;
}

.profileBtnText {
    color: #FFFFFF;
    position: absolute;
    width: 194px;
    height: 22px;
    left: 60px;
    top: 11.28px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.03em;
}

.profileTextContent {
    position: absolute;
    width: 216px;
    height: 15px;
    left: 76px;
    top: 656px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    letter-spacing: 0.03em;
    color: #595959;
}

.invalid-feedback {
    color: #dc3545;
    display: block;
    margin-top: 0.4rem;
    font-size: 0.8rem;
    position: absolute;
    top: 36px;
    left: 5px;
    text-align: initial;
    line-height: 1.3;
    white-space: nowrap;
}

.commonQuestionsLabel {
    left: 103px;
    font-size: 40px;
    line-height: 60px;
    display: flex;
    top: 288px;
    text-align: initial;
    width: 555px;
}

.industryLabel {
    top: 285px;
}

.roleLabel {
    top: 330px;
}

.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    position: absolute !important;
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 22px !important;
    line-height: 33px !important;
    display: flex !important;
    align-items: center !important;
    color: #AEB6CF !important;
}

label+.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root {
    margin-top: 24px !important;
}

label+.css-1ptx2yq-MuiInputBase-root-MuiInput-root {
    margin-top: 24px !important;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #AEB6CF !important;
}

.industryField {
    left: 103px;
    top: 350px;
    position: absolute;
}

.roleField {
    left: 103px;
    top: 390px;
    position: absolute;
}

.peopleField {
    left: 103px;
    top: 410px;
    position: absolute;
}

.companyField {
    left: 103px;
    top: 418px;
    position: absolute;
}

.websiteField {
    left: 103px;
    top: 418px;
    position: absolute;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    width: 581.5px !important;
}

.css-a3l6o-MuiInputBase-root-MuiInput-root-MuiSelect-root:after {
    border-bottom: #AEB6CF !important
}

.css-1ptx2yq-MuiInputBase-root-MuiInput-root:after {
    border-bottom: #AEB6CF !important;
}

.css-1d1r5q-MuiFormHelperText-root.Mui-error {
    color: #dc3545;
    display: block;
    font-size: 100%;
}

.css-1d1r5q-MuiFormHelperText-root {
    color: #dc3545 !important;
    display: block;
    font-size: 100% !important;
}

.disableBtn {
    pointer-events: none;
    opacity: 0.5;
}

.finishText {
    color: white;
    font-size: 21px;
    position: absolute;
    left: 18px;
    top: 7px;
}

.finishBtn {
    position: absolute;
    width: 85px;
    height: 52px;
    left: 721px;
    top: 698px;
    background: #18A0FB;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
}

.updradeText {
    left: 7px;
    top: 11px;
    font-size: 12px;
    line-height: 18px;
    display: flex;
}

.createSurveyText {
    position: absolute;
    left: 11px;
    top: 12px;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    color: #FFFFFF;
}

.MuiFormControlLabel-label {
    font-family: 'Poppins' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 20px !important;
    line-height: 24px !important;
    letter-spacing: -0.015em !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
    color: #AEB6CF !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
    color: #18A0FB !important;
}

.toggle .css-1yjjitx-MuiSwitch-track {
    background: #8A94A6;
}

@media screen and (max-width: 1368px){
    .commonQuestionsLabel{
        font-size: 30px;
        line-height: 40px;
    }
}

@media screen and (max-width: 768px){
    .commonQuestionsLabel{
        font-size: 24px;
        line-height: 35px;
    }
}

