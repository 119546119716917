// Colors //
$white:#FFFFFF;

// Screen Size //
$screen_1368px: 1368px;
$screen_1024px: 1024px;
$screen_768px: 768px;
$screen_540px: 540px;

// Mixin //
@mixin fontFamily($fontFamily, $fontSize, $fontWeight, $lineHeight) {
  font-family: $fontFamily, sans-serif;
  font-size: $fontSize;
  font-weight: $fontWeight;
  line-height: $lineHeight;
}

@mixin font($fontFamily) {
  font-family: $fontFamily;
}

@mixin flex($alignItems, $justifyContent) {
  display: flex;
  align-items: $alignItems;
  justify-content: $justifyContent;
}
