.info-form-container {
    background-color: #F4F4F4; /* Light gray background */
    padding: 20px;
  }
  .info-card {
    border-color: #004085; /* Blue border */
  }
  .info-card .card-header {
    background-color: #007BFF; /* Bootstrap primary blue */
    color: white;
  }
  .save-btn {
    background-color: #007BFF; /* Matching the Bootstrap primary button color */
    border-color: #007BFF;
  }
  .save-btn:hover {
    background-color: #0056B3;
    border-color: #0056B3;
  }