
.googleIconCircle {
    position: absolute;
    width: 35.84px;
    height: 35.84px;
    left: 195px;
    top: 616.28px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(10, 68, 106, 0.15);
    border-radius: 17px;
}

.googleIcon {
    position: absolute;
    width: 21.15px;
    height: 21.15px;
    left: 7.31px;
    top: 7.31px;
}

.msalIconCircle {
    position: absolute;
    width: 35.84px;
    height: 35.84px;
    left: 256.84px;
    top: 616.28px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(10, 68, 106, 0.15);
    border-radius: 17px;
}

.msalIcon {
    position: absolute;
    width: 26.15px;
    height: 26.15px;
    left: 5.31px;
    top: 5.31px;
}