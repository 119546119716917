.header {
  margin-top: 12%;
  margin-left: 10%;
  display: grid;
  align-items: center;
}

.heading {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  display: flex;
  align-items: center;
  color: #181818;
  margin-top: 24px;
}

.logo {
  width: 121.94px;
  height: 88px;
  background: inherit;
  margin: 0;
}

.label {
  position: relative;
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #181818;
  padding-bottom: 10px;
  padding-top: 30px;
}

.input {
  position: relative;
  /* width: 313.97px;
  height: 41.31px; */
  width: 100%;
  height: 40px;
  /* border: 1px solid rgba(174, 182, 207, 0.25); */
  /* filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.25)); */
  border-radius: 12px;
}

.submit_btn {
  position: relative;
  background: #18A0FB;
  box-shadow: 0px 4px 10px rgba(24, 160, 251, 0.15);
  border: none;
  border-radius: 12px;
  /* width: 313.97px;
  height: 41.31px; */
  width: 100%;
  height: 40px;
  margin-top: 50px;
  padding-top: 10px;
  text-decoration:none !important;
}

.submit_btn-txt {
  justify-content: center;
  display: flex;
  letter-spacing: 0.03em;
  color: #FFFFFF;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.divider-text {
  position: relative;
  /* width: 104px; */
  width: 35%;
  height: 0px;
  border: 1px solid #AEB6CF;
}

.signText {
  /* width: 110.51px; */
  width: 30%;
  height: 18.59px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.03em;
  color: #181818;
}

.divider {
  position: relative;
  display: flex;
  align-items: center;
}

.content {
  position: relative;
  margin-left: 10%;
  margin-top: 4%;
  /* float: left; */
  display: grid;
}

.social_login_button {
  margin-top: 20px;
}

.social_login_section {
  margin-top: 30px;
  display: grid;
}

.ms-btn {
  width: 35.84px;
  height: 35.84px;
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(10, 68, 106, 0.15);
  border: none;
  border-radius: 17px;
  padding-top: 8px;
  padding-right: 6px;
}

.forgotPwd {
  position: relative;
  top: 50px;
  left: 100px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.03em;
  text-decoration-line: underline;
  color: #8A94A6;
}

.forgotPasswordLink{
    transition: all 0.3s ease;
}
.forgotPasswordLink:hover{
    transition: all 0.3s ease;
    text-decoration: underline;
}