.previewDialog {
    .MuiDialog-paper {
        max-width: 100% !important;
        height: 100%;
        width: 100%;
        max-height: 100%;
        margin: 0;

        @media screen and (max-width: $screen_768px) {
            display: flex !important;
            margin: 0 !important;
        }
    }

    .previewDialogInner {
        padding: 0px !important;
        background: #fafafa;

        .headerPre {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: #fff;
            padding: 2px 20px;
            border-bottom: 1px solid #ccc;
            margin-bottom: 20px;

            .leftSide {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .arrow {
                    cursor: pointer;
                }

                .logoHead {
                    cursor: pointer;
                    margin-left: 7px;

                    img {
                        width: 41px;
                        height: auto;
                    }
                }
            }

            h2 {
                font-size: 18px;
            }

            .cancelIcon {
                cursor: pointer;
            }
        }

        .footerPre {
            position: absolute;
            bottom: 15px;
            left: 0;
            right: 0;
            width: 100%;
            text-align: center;

            .btnGroup {
                margin: 0 auto;
                display: inline-block;

                .btn {
                    background: #fff;
                    border-radius: 3px;
                    font-size: 15px;
                    line-height: 22px;
                    color: #18a0fb;
                    border: 1px solid #18a0fb;
                    padding: 5px 20px;
                    margin: 0 5px;

                    &:hover {
                        opacity: 0.8;
                    }

                    &.btnDefault {
                        color: #ffffff;
                        background: #18a0fb;
                    }
                }
            }
        }
    }
}

.mainBodyPre {
    width: 1366px;
    max-width: 100%;
    height: 100%;
    overflow: auto;
    max-height: calc(100% - 140px);
    margin: 0 auto;
    border: 1px solid #ccc;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 3px;
    padding: 50px;

    @media screen and (max-width: 1024px) {
        padding: 20px;
    }

    @media screen and (max-width: $screen_540px) {
        padding: 20px 30px;
    }

    .layoutWrap {
        &.layout0 {
            @media screen and (max-width: 1024px) {
                .questionAnswerWrap {
                    padding: 20px;
                }
            }

            .multipleInner {
                &.vertical {
                    .item {
                        width: 90%;
                    }
                }

                &.yesNoInner {
                    .item {
                        width: 90%;
                    }
                }
            }
        }
    }

    &.desktop {
        width: 1366px;

        @media screen and (max-width: 1024px) {
            .bodyInner {
                &.previewBodyInner {
                    .layoutWrap {
                        display: flex;
                        flex-direction: column;
                        width: 100%;
                        height: auto;
                        padding: 20px 30px;

                        .imgWrap,
                        .ImageWrap {
                            width: 100% !important;
                            height: 300px !important;

                            img {
                                object-fit: cover;
                            }
                        }

                        .imageInner {
                            width: 100% !important;
                            max-width: 100%;
                            height: 100% !important;

                            img {
                                object-fit: cover;
                            }
                        }

                        .questWrap {
                            width: 100% !important;
                        }

                        .questionAnswerWrap {
                            padding: 20px 30px;
                        }

                        &.autoAdjustable{
                            .imgWrap,
                            .ImageWrap {
                                height: auto !important;
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $screen_768px) {
            .bodyInner {
                &.sliderWrap {
                    height: auto;
                    padding: 0 50px;
                    margin: 0;

                    .slider {
                        .ant-carousel {
                            .slick-slider {
                                .slick-list {
                                    .slick-track {
                                        .slick-slide {
                                            .questionAnswerWrap {
                                                padding: 30px;
                                                height: 100%;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 600px) {
            .bodyInner {
                &.sliderWrap {
                    padding: 0;

                    .slider {
                        .ant-carousel {
                            .slick-slider {
                                .slick-next {
                                    right: -28px;
                                    z-index: 100;
                                    width: 30px;
                                    height: 30px;
                                }

                                .slick-prev {
                                    left: -28px;
                                    z-index: 100;
                                    width: 30px;
                                    height: 30px;
                                }

                                .slick-list {
                                    .slick-track {
                                        .slick-slide {
                                            .questionAnswerWrap {
                                                padding: 20px 30px;

                                                h3 {
                                                    font-size: 16px;
                                                    line-height: 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &.previewBodyInner {
                    .layoutWrap {
                        padding: 0;

                        .ImageWrap {
                            padding: 20px 30px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: $screen_768px) {
            .logoTitleTime {
                margin-bottom: 125px !important;

                .title {
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                    top: 65px;
                    width: 100% !important;
                    margin: 0 !important;
                }
            }

            .layout4 {

                .imageWrapInner,
                .questionAnswerWrap {
                    padding: 0;
                }

                .imageWrapInner {
                    .ratingInner {
                        .rs-rate-character {
                            width: 24px;
                            height: 20px;
                            justify-content: center;
                            display: flex;

                            @media screen and (max-width: 576px) {
                                width: 16px;
                            }

                            svg {
                                width: 24px;
                                height: auto;

                                @media screen and (max-width: 576px) {
                                    width: 16px;
                                }
                            }
                        }
                    }

                    .selectInner {
                        width: 100%;
                    }

                    .imageUploadWrap {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        gap: 10px;

                        .imageUpload {
                            margin: 0 0 10px 0;
                            width: 100%;
                        }
                    }
                }
            }
        }

        &.mainFullWidth{
            width: 100%;
            padding: 0;
            .bodyInner {
                padding: 0;
                margin: 0;
                width: 100%;
            }

            .layout0.helpLayout{
                .ImageWrap{
                    .rightSideImage{
                        img{
                            width: 100% !important;
                            height: auto !important;
                            object-fit: contain;

                            &.autoSize{
                                max-height: max-content;
                            }
                        }
                    }
                }
            }
            .layout5.helpLayout{
                .imgWrap{
                    .rightSideImage{
                        img{
                            width: 100% !important;
                            height: auto !important;
                            object-fit: contain;

                            &.autoSize{
                                max-height: max-content;
                            }
                        }
                    }
                }
            }

            .layout5{
                .imgWrap{
                    .rightSideImage{
                        img{
                            object-fit: contain;
                            &.autoSize{
                                max-height: max-content;
                            }
                        }
                    }
                }
            }

            .layout1.imageLayoutAuto{
                .layoutFull{
                    .ImageWrap{
                        .rightSideImage{
                            width: 100% !important;
                            height: auto !important;
                            max-height: max-content;
                            max-width: 100%;
                            padding: 0;
                            text-align: center;
                            img{
                                max-height: max-content;
                                max-width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .decoratorImage{
            padding: 0;
            width: 100%;
            .imageBottom{
                img{
                    width: 100%;
                    height: 80px;
                }

                &.imageRotate{
                    transform: rotate(180deg);
                }
            }
        }
    }

    &.mobile {
        width: 414px;

        .layoutWrap {
            .multipleInner {
                display: block !important;

                .item {
                    width: 90%;
                }

                &.yesNoInner {
                    .item {
                        width: 100%;
                        margin-right: 0;
                    }
                }
            }

            &.layout1 .imageInner.rightSideImage {
                max-width: 100% !important;
            }

            &.layout0,
            &.layout5 {
                height: auto;

                .imageInner.rightSideImage {
                    height: auto;
                }
            }

            .questWrap {
                width: 100% !important;
            }

            .ImageWrap {
                width: 100% !important;

                .imageInner.rightSideImage {
                    height: 100%;

                    img {
                        object-fit: cover;
                    }
                }
            }

            //   .multipleInner {
            //   }
        }
    }

    .bodyHead {
        max-width: 100%;
        width: 100%;

        .logoTitleTime {
            padding: 0 180px;
            position: relative;
            width: 100%;
            margin-bottom: 20px;
            min-height: 20px;

            @media screen and (max-width: $screen_540px) {
                padding: 0;
            }

            .logo {
                position: absolute;
                left: 0;
                top: 0;
                width: 121px;
                height: 55px;
                background: #f0eaea;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                border: 1px solid #ccc;

                @media screen and (max-width: $screen_540px) {
                    width: 100px;
                }

                img {
                    max-width: 100%;
                    height: auto;
                    max-height: 100%;
                }
            }

            .title {
                margin: 0 20px;
                width: calc(100% - 40px);
                min-height: 55px;
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 22px;
                font-weight: bold;
                border-radius: 3px;
                border: 1px solid #ccc;
                padding: 0 10px;
                text-align: center;

                @media screen and (max-width: $screen_768px) {
                    font-size: 18px;
                }
            }

            .time {
                position: absolute;
                right: 0;
                top: 0;
                height: 55px;
                width: 150px;
                background: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 3px;
                font-size: 14px;
                line-height: 27px;
                color: #319eb8;
                border: 1px solid #ccc;

                @media screen and (max-width: $screen_540px) {
                    width: 110px;
                    font-size: 12px;
                }

                svg {
                    font-size: 21px;
                    margin-right: 5px;
                }

                span {
                    width: 50px;
                    text-align: center;
                }
            }
        }

        .description {
            margin: 0 200px 20px 200px;
            padding: 15px 20px;
            width: calc(100% - 400px);
            border-radius: 3px;
            background: #fff;
            font-style: italic;
            font-size: 18px;
            line-height: 27px;
            color: #000000;
            text-align: center;
            border: 1px solid #ccc;
            word-break: break-word;

            @media screen and (max-width: $screen_768px) {
                width: 100%;
                margin: 0;
            }
        }
    }

    .bodyInner {
        padding: 0 180px;
        margin: 0 20px;
        width: calc(100% - 40px);

        .slick-slide {
            padding-right: 1px;
        }

        @media screen and (max-width: 1024px) {
            padding: 0 50px;
            margin: 50px 20px;

            &.preview-formWrap {
                margin-bottom: 0;
            }
        }

        @media screen and (max-width: $screen_768px) {
            padding: 0;
            margin: 50px auto;
            width: 100%;
        }

        @media screen and (max-width: $screen_540px) {
            .questionAnswerWrap {
                padding: 0;
            }
        }
    }

    .bodyFooter {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;

        padding: 0 180px;
        margin: 0 20px;
        width: calc(100% - 40px);

        @media screen and (max-width: 1024px) {
            padding: 0 50px;
        }

        @media screen and (max-width: $screen_768px) {
            padding: 0;
            margin: 0;
            width: 100%;
        }

        button.btn {
            &.submit {
                //   height: 60px;
                height: 40px;
                background: #ffffff;
                border-radius: 4px;
                //   font-size: 25px;
                font-size: 1rem;
                color: #319eb8;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 20px auto 0;
                //   padding: 0 40px;
                padding: 0 30px;
                border: 1px solid #319eb8;

                &:hover {
                    opacity: 0.8;
                }
            }
        }

        .footerContent {
            display: block;
            width: 100%;
            text-align: center;
            margin-top: 20px;
            padding: 10px;
            background: #fff;
            border-radius: 5px;
            border: 1px solid #ccc;

            p {
                font-style: italic;
                font-weight: 300;
                font-size: 18px;
                line-height: 27px;
                text-align: center;
                color: #000;
                margin: 0;
                //   text-shadow: 0 0 4px #000;
                word-break: break-word;

                a {
                    color: #fff;
                    text-decoration: underline;
                }
            }
        }

        .footerBtnWrap {
            background: $white;
            width: 100%;
            padding-bottom: 30px;
        }
    }

    &.mobile {
        .bodyInner {
            margin: 0;
            width: 100%;

            &.previewBodyInner {
                .layoutWrap {
                    margin: 20px 20px 20px;
                    padding: 20px;
                    height: auto;
                    display: flex;
                    flex-direction: column;

                    .imgWrap {
                        width: 100% !important;
                        height: 300px !important;

                        img {
                            object-fit: cover;
                        }
                    }

                    .questionAnswerWrap {
                        padding: 20px;
                    }
                }
            }
        }

        .bodyFooter {
            padding: 0;

            .footerContent {
                margin: 20px 0;
            }
        }
    }

    .layoutWrap {
        background: #fff;
        border-radius: 5px;
        margin: 20px 0;
        padding: 20px;
        height: 400px;
        //box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.21);
        border: 1px solid #ccc;
        overflow-y: auto;

        &.autoAdjustable {
            height: auto !important;
        }

        &:last-child {
            margin-bottom: 0;
        }

        .contactInner {
            input {
                padding: 0;
            }
        }

        .imageInner {
            &.rightSideImage {
                position: relative;
            }
        }

        &.layout1 {
            .imageInner {
                &.rightSideImage {
                    width: 380px !important;
                    height: auto !important;
                    max-width: 380px;
                    padding-top: 30px;
                    margin-bottom: 20px;
                    position: relative;
                }
            }
        }

        &.layout2,
        &.layout3 {
            height: 391px;

            .imageInner {
                &.rightSideImage {
                    padding: 20px;
                    overflow: hidden;
                    height: 100%;
                    width: 100%;
                }
            }

            .ImageWrap {
                .imageWrapInner {
                    overflow: auto;
                }
            }
        }

        &.layout5,
        &.layout0 {
            height: 391px;
            padding: 20px;

            .imageInner {
                &.rightSideImage {
                    padding: 0;
                    overflow: hidden;
                    height: 100%;
                    width: 100%;
                }
            }

            .ImageWrap {
                .imageWrapInner {
                    overflow: auto;
                }
            }
        }

        &.layout0{
            &.layoutColumn{
                display: flex;
                //flex-direction: column-reverse;
                .ImageWrap, .questWrap{
                    width: 100% !important;
                    .imageInner {
                        img{
                            max-height: fit-content;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        &.layout6 {
            height: 391px;
            padding: 0;
            position: relative;

            .imageInner {
                &.rightSideImage {
                    padding: 0;
                    overflow: hidden;
                    height: 100%;
                    width: 100%;
                }
            }

            .layout6Inner {
                border-radius: 5px;
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 1;
                background: rgba(255, 255, 255, 0.4);
                overflow: auto;
            }

            .ImageWrap {
                .imageWrapInner {
                    overflow: auto;

                    input,
                    textarea {
                        background: transparent;
                    }
                }
            }
        }
    }

    .questionAnswerWrap {
        // padding: 50px;
        padding: 20px 30px;

        .answerWrap {
            label {
                width: 100%;

                div {
                    width: 100%;
                }
            }

            textarea {
                border: none;
                outline: none;
                resize: none;
                height: 30px;
                font-style: italic !important;
                font-weight: 400 !important;
                font-size: 18px;
                line-height: 27px;
                color: #a3daff !important;
                min-height: 42px;
                width: 100% !important;
                border-bottom: 2px solid #a3daff;
            }
        }

        .btnSave {
            height: 32px;
            padding: 5px 25px;
            color: #fff;
            font-size: 14px;
            background: #18a0fb;
            border-radius: 4px;
        }
    }

    .ques {
        h3 {
            font-weight: 400;
            font-size: 18px;
            line-height: 24px;
            color: #8a94a6;
            height: auto;
            min-height: 40px;
            width: 100%;
            word-break: break-word;
        }
    }

    .imageWrapInner {
        .btnSave {
            height: 32px;
            padding: 5px 25px;
            color: #fff;
            font-size: 14px;
            background: #18a0fb;
            border-radius: 4px;
        }

        .imageUploadWrap {
            height: auto;
            overflow: visible;
            // margin-top: 20px;
        }
    }

    .multipleInner {
        padding-top: 30px;
        justify-content: flex-start;
        max-height: unset;
        white-space: nowrap;

        @media screen and (max-width: $screen_768px) {
            flex-direction: column;
        }

        &.horizontal{
            white-space: wrap;
            overflow: visible;
            flex-wrap: wrap;
            gap: 10px;
            .item{
                margin: 0;
            }
        }

        .item {
            cursor: pointer;
            align-self: stretch;
            // height: auto;
            width: auto;
            min-width: 30%;

            p {
                padding: 5px 10px 5px 33px;
                word-break: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                white-space: break-spaces;
                line-height: 21px;
            }

            &.selected {
                border-color: #18a0fb;
            }

            input[disabled] {
                cursor: pointer;
            }
        }

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            display: block;
        }

        &::-webkit-scrollbar-track {
            background: #d9d9d9;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb {
            background: #acaeb6;
            border-radius: 10px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .contactInner {
        margin-top: 30px;
    }

    .fileUploadInner {
        .fileUpload {
            position: relative;

            .uploadIcon {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            input {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                z-index: 1;
                cursor: pointer;
                opacity: 0;
            }
        }
    }

    .slick-slider {
        .slick-list{
            .slick-track{
                .slick-slide {
                    .layoutWrap{
                        height: 440px !important;
                        padding: 0;
                        .ImageWrap{
                            height: 100% !important;
                            @media screen and (max-width: 600px){
                                padding: 0;
                            }
                            .imageWrapInner{
                                @media screen and (max-width: 768px){
                                    padding: 20px 30px;
                                }
                                .imageInner{
                                    padding-top: 0;
                                    @media screen and (max-width: 1024px){
                                        max-height: 250px;
                                    }
                                }
                            }
                        }
                        .layoutFull{
                            .ImageWrap{
                                @media screen and (max-width: 1024px){
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.previewDialog {
    .previewDialogInner {
        .mainBodyPre {
            &.mobile {
                padding: 0;

                @media screen and (max-width: $screen_540px) {
                    width: 90%;
                    margin: 20px auto;

                    .bodyInner {
                        .layoutWrap {
                            margin: 20px;
                        }
                    }
                }

                .bodyInner {
                    padding: 0;

                    &.sliderWrap {
                        height: 100%;

                        @media screen and (max-width: $screen_768px) {
                            padding: 0 20px;
                        }

                        .slider {
                            height: 100%;

                            .ant-carousel {
                                height: 100%;

                                .slick-slider {
                                    height: 100%;

                                    .slick-list {
                                        height: 100%;

                                        .slick-track {
                                            height: 100%;

                                            .slick-slide {
                                                height: 100%;

                                                >div {
                                                    height: 100%;
                                                    padding-bottom: 50px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .slick-arrow.slick-prev {
                                left: 5px;
                                z-index: 1;
                                opacity: 0.5;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 35px;
                                height: 35px;

                                @media screen and (max-width: $screen_540px) {
                                    left: 0;
                                }
                            }

                            .slick-arrow.slick-next {
                                right: 5px;
                                z-index: 1;
                                opacity: 0.5;
                                top: 50%;
                                transform: translateY(-50%);
                                width: 35px;
                                height: 35px;

                                @media screen and (max-width: $screen_540px) {
                                    right: 0;
                                }
                            }

                            .introCard,
                            .layoutWrap,
                            .finalSubmitCard {
                                height: 100% !important;
                                max-width: 80%;
                                margin-left: auto;
                                margin-right: auto;
                            }

                            .introCard {
                                .logoTitle {
                                    padding: 0;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: center;

                                    .logo {
                                        position: relative;
                                        left: 0;
                                        top: 0;
                                    }

                                    .title {
                                        border: none;
                                        height: auto;
                                        font-size: 18px;
                                    }
                                }

                                .description {
                                    padding: 10px 20px;
                                }
                            }

                            .layoutWrap {
                                display: flex;
                                flex-direction: column;
                                overflow: auto;

                                @media screen and (max-width: $screen_768px) {
                                    overflow-y: auto;
                                }

                                .imgWrap,
                                .questWrap {
                                    width: 100% !important;
                                }
                            }

                            .imageWrapInner {
                                padding: 20px 10px;

                                .contactInner {
                                    flex-direction: column;
                                    align-items: flex-start;
                                    margin-bottom: 10px;

                                    .flag {
                                        margin: 0 0 10px 0;
                                        width: 100%;

                                        >div {
                                            width: 100%;
                                        }

                                        ul{
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            .multipleInner {
                                padding: 0;
                                display: flex;
                                flex-direction: column;

                                .item {
                                    width: 100%;
                                    margin-right: 0;
                                }
                            }

                            .footerContent {
                                padding: 10px;
                            }

                            .questionAnswerWrap {
                                padding: 30px 10px;
                            }
                        }
                    }

                    .notSlider {
                        height: auto;

                        .layoutWrap {
                            display: flex;
                            flex-direction: column;
                            height: auto;

                            .imgWrap,
                            .questWrap {
                                width: 100% !important;

                                .imageInner {
                                    img {
                                        height: auto !important;
                                    }
                                }
                            }
                        }

                        .imageWrapInner {
                            padding: 20px 10px;
                        }

                        .multipleInner {
                            padding: 0;
                            display: flex;
                            flex-direction: column;

                            .item {
                                width: 100%;
                                margin-right: 0;
                            }
                        }
                    }

                    .layout4 {

                        .imageWrapInner,
                        .questionAnswerWrap {
                            padding: 0;
                        }

                        .imageWrapInner {
                            .ratingInner {
                                .rs-rate-character {
                                    width: 24px;
                                    height: 24px;
                                    padding: 0;
                                    margin-right: 5px;

                                    @media screen and (max-width: 576px) {
                                        width: 16px;
                                    }

                                    svg {
                                        width: 24px;
                                        height: auto;

                                        @media screen and (max-width: 576px) {
                                            width: 16px;
                                        }
                                    }
                                }
                            }

                            .selectInner {
                                width: 100%;
                            }

                            .imageUploadWrap {
                                display: grid;
                                grid-template-columns: repeat(2, 1fr);
                                gap: 10px;
                            }
                        }
                    }
                }

                .bodyHead {
                    background: #fff;
                    margin: 20px;
                    width: calc(100% - 40px);
                    border-radius: 5px;
                    padding: 10px;

                    .logoTitleTime {
                        padding: 0;
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .title {
                            border: none;
                            height: auto;
                            font-size: 18px;
                            width: 100%;
                            margin-top: 70px;
                        }
                    }

                    .description {
                        padding: 10px 20px;
                        margin: 0;
                        width: 100%;
                    }
                }

                .bodyFooter {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.previewDialogFull {
    .MuiDialog-container {
        .previewDialogInner {
            .mainBodyPre.desktop {
                max-height: 100%;
                margin-top: 0;

                @media screen and (max-width: 1024px) {
                    padding: 20px 30px;
                }

                .bodyFooter {
                    margin-bottom: 20px;
                }
            }
        }
    }
}

.previewThankyou {
    .MuiDialog-container {
        .MuiPaper-root {
            .previewDialogInner {
                .previewContainer {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 100%;
                    background: #f3f4f8;

                    .previewInfo {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        background-color: #ffffff;
                        padding: 35px 60px;
                        border-radius: 5px;

                        svg {
                            margin-bottom: 20px;
                            width: 50px;
                            height: 50px;

                            path {
                                fill: #41c74d;
                            }
                        }

                        h2 {
                            font-size: 3rem;
                            line-height: 1.2;
                            margin-bottom: 10px;
                            text-transform: uppercase;
                            color: #18a0fb;
                            font-weight: 400;
                            text-align: center;
                        }

                        p {
                            font-size: 1.5rem;
                            line-height: 1.2;
                            text-align: center;
                        }

                        @media screen and (max-width: 576px) {
                            width: 90%;
                            margin: auto;
                            padding: 20px;
                            height: 95vh;

                            h2 {
                                font-size: 2rem;
                            }

                            p {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
}