.callmodel .modal-dialog .modal-content {
    width: 200px !important;
    margin: 0 auto;
}
.simplecall_model {
  border: 1px solid #dee2e6;
  border-radius: 5px;
	width: 200px;
	margin: 0 auto !important;
}
.simplecall_model .MuiBox-root {
  text-align: center;
  margin: 10px;
  overflow: hidden;
}
.simplecall_model .css-82jivf-MuiTypography-root {
    margin-bottom: 3px !important;
    font-size: 16px !important;
}
.simplecall_model .callbtn {
  margin: 5px;
}
.simplecall_model .MuiGrid-item {
  padding-top: 0 !important;
}
.simplecall_model .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  width: 125px !important;
  padding: 5px !important;
}
