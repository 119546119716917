.newWrapper{
  width: 100%;
  height: 100%;
}

.signInSinUpWrap{
  background-color: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  *{
    box-sizing: border-box;
  }
  .leftWrapper{
    width: 60%;
    height: 100%;
    text-align: center;
    position: relative;
    img{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
    .profileSetLeftImgWrap{
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      .industry, .industryText{
        left: 0;
        top: inherit;
        bottom: inherit;
        right: inherit;
        position: relative;
        max-width: 100%;
      }
    }
  }

  .rightWrapper{
    width: 40%;
    height: 100%;
    overflow:auto;
    background-color: #F3F4F8;
    height: 100vh;
    padding: 50px 80px 20px 80px;
    position: relative;
    left: auto;

    @media screen and (min-width: 767px) and (max-width: 1200px) {
        padding: 50px 50px 0 50px;
    }
    @media screen and (min-width: 991px) and (max-width: 1200px) {
        width: 50%;
    }
    @media screen and (min-width: 767px) and (max-width: 990px) {
        width: 60%;
    }
    .signUpLink{
      position: absolute;
      right: 80px;
      top: 50px;
      display: flex;
      justify-content: space-around;
      align-items: center;

      @media screen and (min-width: 767px) and (max-width: 1200px) {
        right: 50px;
      }
      .dontAccount{
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.03em;
        color: #595959;
        margin: 0 10px 0 0;
        position: relative;
        width: auto;
        left: auto;
        height: auto;
        top: auto;
      }
      .buttonSignIn{
        border: 1px solid #18A0FB;
        font-size: 10px;
        line-height: 21px;
        // text-align: center;
        letter-spacing: 0.03em;
        color: #18A0FB;
        background: transparent;
        width: 70px;
        border-radius: 0;
        margin: 0;
        position: relative;
        left: auto;
        height: 24px;
        top: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        .signInText{
          position: relative;
          width: 70px;
          height: 100%;
          top: auto;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .header{
      margin: 0;
    }
    .content{
      margin: 0;
    }

    .confirmationWrap{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 80%;
      transform: translate(-50%, -50%);
      .confirm-logo{
        top:-70px;
        left: 50%;
      }
      .confirm-line{
          top: -42px;
        left: calc(50% - 139px);
      }
      .confiration-heading, .confiration-sub-heading, .confiration-link, .confirmationContent{
        position: relative;
        left: 0;
        top: inherit;
        right: inherit;
        bottom: inherit;
        height: auto;
        text-align: center;
        margin-bottom: 15px;
      }
    }
    .signIntoArticencen{
      position: relative;
      .header{
        .profile-logo{
          position: relative;
          left: inherit;
          top: inherit;
        }
        .profile-heading{
          position: relative;
          left: inherit;
          top: inherit;
          bottom: inherit;
          right: inherit;
        }
      }
      .content{
        width: 100%;
        margin-top: 15px;
        .invalid-feedback{
          //   top: 77px;
          top: 70px;
          left: 18px;
          white-space: normal;
          p{
            font-size: 12px;
            br{
              display: none;
            }
          }
        }
        label{
          width: 100%;
          .profileLabel{
            font-size: 14px;
            line-height: 21px;
            letter-spacing: 0.03em;
            color: #181818;
            position: relative;
            text-align: left;
            margin-bottom: 10px;
          }
          .profileInput{
            margin-bottom: 25px;
            input{
              width: 100%;
              //   background: #F3F4F8;
              background: #FFF;
              height: 40px;
              padding-left: 10px;
              &:hover, &:focus{
                border: 1px solid #7ec1ff;
              }
              &::placeholder{
                left: inherit;
              }
            }
          }
        }
        .profileBtn, .profileBtnText, .profileTextContent{
          position: relative;
          left: inherit;
          right: inherit;
          top: inherit;
          bottom: inherit;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          -webkit-user-select: none;
          user-select: none;
          margin-bottom: 10px;
          margin-top: 10px;
          text-decoration: none;
        }
        .profileSignBtn{
            margin-top: 50px;
        }
        .profileBtnText{
          margin-bottom: 0;
          margin-top:0;
          -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
          }
        .profileTextContent{
          justify-content: flex-start;
          margin-top:0;
        }
      }
    }
    &.profileSetRightWrap{
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      padding-bottom:70px ;
      .skipBtn, .nextIcon{
        left: inherit;
        right: 20px;
      }
      .industryLabel, .welcomeText, .industryField, .roleLabel, .roleField, .commonQuestionsLabel, .companyField, .peopleField, .websiteField{
        position: relative;
        left: inherit;
        top: inherit;
        bottom: inherit;
        right: inherit;
        width: 100%;
        .MuiFormControl-root{
          min-width: 100%;
          margin-left:0;
          margin-right:0;
          .css-1x51dt5-MuiInputBase-input-MuiInput-input{
            width: 100% !important;
          }
        }
      }
      .nextIcon{
        bottom: 20px; 
        left: unset; 
        top:unset;  
        right: 40px;
        @media screen and (max-width: 768px){
            right: 20px;
        }
      }
      .backIcon{bottom: 20px; left: unset; top:unset; right: 100px}
      .finishBtn{
        bottom: 20px; 
        left: unset; 
        top:unset; 
        right: 10px;
        .finishText{
            left: 15px;
            top: 10px;
        }
      }
    }
  }

  @media screen and (max-width: 768px){
    &.profileQuestionsWrap{
        height: 100vh;
        position: unset;
        flex-direction: column;
        justify-content: flex-start;
        background-color: #F3F4F8;
        .profileLeftWrapper{
            width: 100%;
            height: auto;
            padding: 20px 0;
            height: 45vh;
            .profileSetLeftImgWrap{
                width: 100%;
                position: unset;
                transform: none;
                img{
                    width: 50%;
                }
                .industryText{
                    font-size: 20px;
                    line-height: 30px;
                }
            }
        }
        .profileSetRightWrap{
            width: 100%;
            height: auto;
            padding: 40px;
            justify-content: flex-start;
            position: relative;
            .skipBtn{
                top: 20px;
                position: fixed;
                left: 20px;
            }
            .nextIcon, .backIcon, .finishBtn{
                position: fixed;
                bottom: 20px;
            }
        }
    }
  }
  @media screen and (max-width: 540px){
    &.profileQuestionsWrap{
        .profileLeftWrapper{
            .profileSetLeftImgWrap{
                .industryText{
                    font-size: 18px;
                    padding: 0 15px;
                    line-height: 30px;
                }
            }
        }
        .profileSetRightWrap{
            padding: 40px 20px;
        }
    }
  }
}

.sinUpWrap{
  .header{
    font-style: normal;
    .signup-heading {
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      margin-top: 15px;
    }
    .signup-sub-heading{
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
    }
  }
  .content{
    // width: 325px;
    .inputWrap{
      margin-top: 20px;
      label{
        width: 100%;
        .signUpEmailLabel{
          position: relative;
          left: auto;
          right: auto;
          top: auto;
          bottom: auto;
          width: 100%;
          text-align: left;
          margin-bottom: 5px
        }
        .signUpEmail{
          position: relative;
          left: auto;
          right: auto;
          top: auto;
          bottom: auto;
          width: 100%;
          input{
            position: relative;
            left: auto;
            right: auto;
            top: auto;
            bottom: auto;
            width: 100%;
          }
        }
      }
    }
    .checkWrap{
      position: relative;
      display: inline-flex;
      margin: 20px 0;
      label{
        width: 15px;
      }
      .signupCheckbox{
        right: auto;
        bottom: auto;
        position: absolute;
        left: 0;
        // top: 2px;
        top: 3.5px;
        width: 10px;
        height: 10px;
      }
      .checkBoxText{
        // font-size:10px;
        font-size: 12px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        letter-spacing: -0.015em;
        color: #8A94A6;
        width: calc(100% - 15px);
        text-align: left;
      }
    }
    .signUpBtn{
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      margin-bottom: 20px;

      &.customBtn{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        .signUpBtnText{
            position: unset;
            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
            -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
        }
      }
    }
    .social_login_button{
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 15px;
      .googleIconCircle, .msalIconCircle{
        position: relative;
        left: auto;
        right: auto;
        top: auto;
        bottom: auto;
        cursor: pointer;
        margin: 0 10px;
      }
    }
    .signUpTextContent{
      position: relative;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      width: 100%;
      height: auto;
      text-align: left;
    }
  }
}
@media(max-width: 991px){
  .signInSinUpWrap{
    &.sinUpWrap{
      .rightWrapper{
        .header{
          .signup-heading{
            font-size: 40px;
          }
        }
        .content {
          max-width: 100%;
          .checkWrap{
            label{
              width: 15px;
            }
          }
          .signUpBtn{
            width: 100%;
            .signUpBtnText{
              width: 100%;
            //   position: relative;
              left: 0;
            }
          }
          .divider{
            .divider-text{
              width: calc(50% - 50px);
            }
          }
        }
      }
    }
  }
}
@media(max-width: 767px){
  .signInSinUpWrap{
    .leftWrapper{
      display: none;
    }
    .profileLeftWrapper{
        display: block;
    }
    .rightWrapper{
      width: 100%;
      padding: 25px;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
    }
  }
}
@media(max-width: 767px) {
  .signInSinUpWrap {
    .rightWrapper {
      font-style: normal;
      button{
        &.btn{
          &.btnDefault{
            width: 100%;
          }
        }
      }
      .signUpLink {
        right: 15px;
        top: 15px;
      }
      .content{
        width: 100%;
        label{
          width: 100%;
          .labelField{
            width: 100%;
          }
          .input{
            width: 100%;
            input{
              width: 100%;
            }
          }
        }
        .forgotPwd{
          text-align: right;
          left: auto;
        }
        .submit_btn{
          width: 100%;
        }
        .social_login_section{
          width: 100%;
          .divider{
            .divider-text{
              width: calc(50% - 55.5px);
            }
          }
        }
      }
    }
  }

}

.confirmationDialog{
    .MuiDialog-container{
        .MuiPaper-root {
            @media screen and (max-width: 540px){
                width: 100%;
                .dialog-paper-content{
                    min-width: 100%;
                }
            }
        }
    }
}
